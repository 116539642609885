@import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap');
*{
  margin: 0;
  box-sizing: border-box;
  font-family: "Platypi", serif;
  /* font-family: Arial, Helvetica,sans-serif; */
  scroll-behavior: smooth;
}
:root {
  --box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
                rgba(0, 0, 0, 0.22) 0px 10px 10px;
                
  --box-shadow1:rgba(83, 83, 151, 0.19) 0px 10px 20px,
                rgba(83, 83, 151, 0.23) 0px 6px 6px;
}
a{
  text-decoration: none;
  color: rgb(119, 119, 255);
  &:hover{
    color: white;
  }
}
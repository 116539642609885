.results-container{
    display: flex;
    margin-top: 150px;
    width: 100%;
    align-items: center;
    justify-content: center;

}
.results-card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 80%;
}

.noresults{
    background-color: rgb(227, 164, 164);
    width: 100%;
    /* height: 100%; */
    text-align: center;
    font-size: 28px;
    /* margin-top: 100px; */
    margin-bottom: 250px;
}
@media screen and (max-width:600px) {
    .results-card-container{
        width: 100%;
    }
}
.notfound-container{
    margin: 200px 0px;   
    display: flex;
    flex-direction: column;
    align-items: center;
}
.notfound-container h2{
    border: none;
    color: black;
    box-shadow: none;
}
.notfound-container .notfound{
    &:hover{
        color: blue;
    }
}

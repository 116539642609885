.bearing-container{
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    box-shadow: var(--box-shadow);
    padding: 30px;
    border-radius: 20px;
    color: rgb(83, 83, 151);
    
}
.spindle-detail{
    display: flex;
    align-items: center;
}
.spindle-detail img{
    width: 300px;
    height: 250px;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    cursor: pointer;
}
.spindle-detail .air-def{
    font-size: 18px;
}
.spindle-detail .whyus{
    font-weight: bold;
    &:hover{
     color: rgb(83, 83, 151); ;
    }
}

.bearing-container h1{
    margin-bottom: 20px;
    font-size: 30px;
}
.bearing-container h2{
    font-size: 18px;
    border: none;
    padding: 0px;
    box-shadow: none;
    margin: 0px;
    margin-left: 20px;
}
.bearing-container p{
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
    margin-left: 50px;
}

.why-cards p{
    margin-left: 20px;
}

.video-container iframe{
    width: 100%;
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    padding: 0 16px; 
  }
  
  .content.visible {
    max-height: 1000px; /* Adjust to the maximum height of the content */
    padding: 16px 16px; 
  }


@media screen and (max-width:600px) {
    .bearing-container{
        width: 100%;
        gap: 10px;
        padding: 15px;
    }
    .spindle-detail{
        flex-direction: column;
        gap:20px;
    }
    .spindle-detail .air-def{
        font-size: 16px;
    }
    .bearing-container h1{
        margin-bottom: 10px;
        font-size: 22px;
    }
    
    .bearing-container h2{
        font-size: 18px;
        border: none;
        padding: 0px;
        box-shadow: none;
        margin: 0px;
        margin-left: 5px;
    }
    .bearing-container p{
        color: black;
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 20px;
    }
    .bearing-container span{
        font-weight: bold;
        font-size: 16px;
    }
    .video-container iframe {
        height: 230px;
        width: 345px; 
    }
    .content.visible {
        max-height: 1500px; /* Adjust to the maximum height of the content */ 
      }
}
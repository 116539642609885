.valve-container{
    margin-top: 100px;
    width: 80%;
    box-shadow: var(--box-shadow);
    padding: 20px;
    
}
.valve-container h1{
    color: rgb(83,83,151);
    margin-bottom: 10px;
    font-size: 30px;
}

.valve-container img{
    width: 400px;
    height: 300px;
    transition: all ease-in 0.3s;
    box-shadow: var(--box-shadow);
    &:hover{
       transform: scale(1.1);
    }
    cursor: pointer;
    border-radius: 20px;
}
.valve-container h2{
    border: none;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    margin-top: 15px;
    /* font-size: 18px; */
}
.sv-container{
    padding: 10px;
    margin-top: 15px;
}
.sv-details{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}
.sv-exp p{
    margin-top: 10px;
}
.sv-def{
    margin-left: 20px;
}

@media screen and (max-width:600px) {
    .valve-container{
        width: 100%;
    }
    .valve-container h1{
        font-size: 24px;
    }
    .sv-details{
        flex-direction: column;
        margin-top: 10px;
    }
    .valve-container img{
        width: 300px;
        height: 230px;
        &:hover{
          transform: scale(1.05);
        }
    }
    .sv-container{
        margin: 0px;
    }
    .sv-container p{
        display: none;
    }
    .valve-container h2{
        margin-top: 10px;
    }
    .sv-container h2{
        margin-top: 0px;
    }
    .sv-details h2{
        display: none;
    }
}
.result-cardcontainer{
 display: flex;
 gap: 40px;
 padding: 20px;
 border-radius: 20px;
 box-shadow: var(--box-shadow);
 background-color:rgb(83, 83, 151) ;
 cursor: pointer;
}
.result-cardcontainer img{
    width: 350px;
    height: 300px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
}
.pr-desc{
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    color: rgb(255, 255, 255);
}
.pr-name{
    box-shadow: var(--box-shadow);
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px;
}
.pr-use{
    font-size: 18px;  
}

@media screen and (max-width:600px) {
    .result-cardcontainer{
       flex-direction: column;
       width: 100%;
       align-items: center;
       justify-content: center;
       gap: 15px;
       }
       .pr-use{
        display: none;
       }
       .pr-name{
        font-size: 18px;
       }
       .result-cardcontainer img{
        width: 340px;
        height: 300px;
        border-radius: 20px;
    }
}
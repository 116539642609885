.install-container{
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    box-shadow: var(--box-shadow);
    padding: 30px;
    border-radius: 20px;
    color: rgb(83, 83, 151);
}
.install-container h2{
    font-size: 18px;
    border: none;
    padding: 0px;
    box-shadow: none;
    margin: 0px;
    margin-left: 20px;
}
.install-container p{
    color: black;
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 20px;
}
.install-container .fp{
    margin-left: 0px;
}
.install-container ul li{
    color: black;
    margin: 10px 10px;
}
.install{
    padding: 10px;
    transition: all ease 0.3s;
    &:hover{
        box-shadow: var(--box-shadow);
    }
   
}

@media screen and (max-width:600px) {
    .install-container{
        width: 100%;
        gap: 10px;
        padding: 15px;
    }
    .install-container h1{
        margin-bottom: 10px;
        font-size: 24px;
    }
    
    .install-container h2{
        margin: 0px;
    }
    .install-container p{
        font-size: 16px;
    }
    .install-container ul li{
        margin: 10px 0px;
    }
}
.footer-container{
    display: flex;
    width: 100%;
    /* bottom: 0; */
    flex-direction: column;
    background-color: rgb(83, 83, 151); 
    margin-top: 40px;
    padding: 20px;
}
.footer-links{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}
.footer-links p{
    color: white;
    margin-top: 10px;
    &:hover{
        transform: scale(1.01);
    }
}
.footer-links p i{
    margin-right: 10px;
}
.footer-links h3{
    color: rgb(255, 255, 255);
    font-size: 20px;
}

.footer-left .text{
    font-size: 32px;
}
.footer-left p a{
    color: rgb(255, 255, 255);
    font-weight: bold;  
}


.ceo{
    margin-bottom: 15px;
}
.footer-links h3{
    margin-bottom: 15px;
}
.footer-right p a{
    color: white;
}

hr{
  margin-top: 20px;
  margin-bottom: 20px;  
}

.footer-bottom{
    display: flex;
    justify-content: space-between;
}
.footer-bottom p{
    color: white;
}
.bottom-links .terms{
    color: white;
    cursor: pointer;
}

@media screen and (max-width:950px) {
    .footer-bottom{
        flex-direction: column;
        gap: 30px;
    }
}

@media screen and (max-width:800px) {
    .footer-links{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 20px;

    }
    .footer-left,.footer-center,.footer-right{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
    .footer-bottom{
        flex-direction: column;
        gap: 30px;
    }
    .footer-left .text{
        text-align: center;
    }
    .bottom-links p{
        font-size: 12px;
    }
}
.lab-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rebeccapurple; */
  margin-top: 50px;
}
.lab-container h2 {
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
}
.laboratory-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: linear-gradient(
    to top,
    rgb(83, 83, 151),
    rgb(162, 162, 247),
    rgb(83, 83, 151)
  );
  width: 80%;
  padding: 40px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}
.creditsicon {
  box-shadow: var(--box-shadow);
  border-radius: 30px;
}

.laboratory-container p {
  width: 60%;
  font-size: 18px;
  box-shadow: var(--box-shadow);
  padding: 20px;
  color: white;
  border-radius: 10px;
}
@media screen and (max-width: 1050px) {
  .creditsicon {
    width: 250px;
    height: 280px;
  }
  .laboratory-container {
    gap: 10px;
  }
  .laboratory-container p {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .laboratory-container {
    width: 100%;
    flex-direction: column;
  }
  .creditsicon {
    width: 270px;
    height: 300px;
  }
  .laboratory-container p {
    margin-top: 20px;
    width: 100%;
    font-size: 16px;
  }
  h2 {
    font-size: 18px;
  }
}

.abt-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.abt-container h2 {
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
}
.aboutus-container {
  background: linear-gradient(
    to left,
    rgb(83, 83, 151),
    rgb(162, 162, 247)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 30px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}
.aboutus-container img {
  width: 350px;
  height: 350px;
  box-shadow: var(--box-shadow);
}
.about-details {
  margin-left: 100px;
}
.about-details h2 {
  color: white;
  border: none;
  padding-left: 10px;
}
.two-text {
  margin-top: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: rgb(83, 83, 151);
  font-size: 20px;
  &:hover {
    color: white;
  }
}
.two-text span {
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .two-text {
    margin-top: 10px;
    font-size: 18px;
    color: white;
  }
  .about-details {
    margin-left: 50px;
  }
  .aboutus-container img {
    width: 300px;
    height: 350px;
  }
}
@media screen and (max-width: 800px) {
  .aboutus-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: rgb(83, 83, 151);
  }
  .abt-container h2 {
    margin-top: 10px;
  }
  .about-details {
    width: 100%;
    gap: 10px;
    margin: 0;
  }
  .aboutus-container img {
    width: 350px;
    height: 400px;
  }
  .about-details h2 {
    font-size: 18px;
  }
  .two-text {
    margin-top: 10px;
    font-size: 16px;
    color: white;
  }
}
@media screen and (max-width: 600px) {
  .aboutus-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .aboutus-container img {
    width: 280px;
    height: 300px;
  }
  .about-details h2{
    padding: 0px;
    font-size: 16px;
  }
}

.airbearing-container{
    display: flex;
    gap: 20px;
    margin-top: 50px;
    width: 80%;
    background: linear-gradient(
        to right,
        rgb(83, 83, 151),
        rgb(83, 83, 151)
      );
    color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 20px;
}
.airbearing-container img{
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    width: 350px;
    height: 300px;
}
.airbearing-details{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.airbearing-details p{
    font-size: 18px;
}
.airbearing-details .links{
    display: flex;
    gap: 40px;
}
.airbearing-details .learn{
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: white;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 10px;
}

@media screen and (max-width:600px) {
    .airbearing-container{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    } 
    .airbearing-container img{
        width: 300px;
        height: 250px;
    }
    .airbearing-details p{
        font-size: 16px;
    }
    .airbearing-details h1{
        font-size: 30px;
    }
    .airbearing-details .learn{
        margin-top: 0px;
        font-size: 16px;
    }
    .airbearing-details .links{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

}


.trng-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.trng-container h2 {
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
}
.training-container {
  background: linear-gradient(
    to bottom,
    rgb(83, 83, 151),
    rgb(144, 144, 255),
    rgb(83, 83, 151)
  );
  width: 80%;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  padding: 40px;
  display: flex;
  gap: 20px;
}
.training-container div h3 {
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  box-shadow: var(--box-shadow);
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
}
.training-container .tran1 {
  flex: 1;
}
.tran2 {
  width: 20%;
}
.tran3 {
  width: 25%;
}
.training-container li {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-top: 10px;
}
.training-container p {
  color: rgb(255, 255, 255);
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .training-container {
    width: 100%;
    flex-direction: column;
    padding: 15px;
  }
  .tan1,
  .tran2,
  .tran3 {
    width: 100%;
  }
  .training-container li,
  .training-container p {
    font-size: 16px;
  }
  .training-container div h3{
       display: flex;
       justify-content: center;
  }
  .tran3{
    margin-bottom: 20px;
  }
}

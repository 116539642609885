.navbar-container{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    background-color: rgb(83, 83, 151);
}

/* .navbar-logo .link{
  text-decoration: none;   
} */
.navbar-logo .link-abbr{
    color: white;
    display: none;
}
.navbar-logo .link h1{
    color: white;
    &:hover{
        transform: scale(1.02);
    }
}

.navbar-link-list{
    display: flex;
    gap: 60px;
}
.navbar-link-list li,.navbar-link-list a{
    list-style: none;
    font-size: 20px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    cursor: pointer;
    &:hover{
        transform: scale(1.1);
    }
}

.navbar-search-icon{
    display: flex;
    align-items: center;
    gap: 25px;
    margin-right: 50px;
    position: relative;
}

.navbar-search-icon i{
    color: rgb(255, 255, 255);
    cursor: pointer;
    &:hover{
       transform: scale(1.1);
    }
}
/* ---------------------------------------------------- */
 
.search-bar {
    width: 0;
    overflow: hidden;
    transition: width 0.5s ease; 
}
/* -----------------------------------start--------------------- */
.search-results {
    position: absolute;
    top: 50px; 
    right: 30px;
    width: 90%;
    border: 1px solid #ddd;
    background-color: #ffffff;
    z-index: 1000;
    border-radius: 20px;
}
.search-result-item {
    padding: 8px;
    cursor: pointer;
}
  
.search-result-item:hover {
    color: rgb(255, 255, 255);
    border-radius: 20px;
    background-color: rgb(83, 83, 151);
}
/* ------------------------------------end---------- */
.search-bar input{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    color:  rgb(119, 119, 255);
}
  
.search-bar.open {
   width: 250px;
}
.ham-icon{
    display: none;
}

@media screen and (max-width:1150px) {
   .navbar-link-list{
    display: none;
   }
   .ham-icon{
    display: block;
    color:  rgb(119, 119, 255);
    cursor: pointer;
    &:hover{
        color: white;
    }
   }
    .navbar-search-icon{
        margin-right: 0px;
    }
    .search-bar.open {
        width: 250px;
    }
    .navbar-link-list {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 60px;
        right: -100%;
        width: 300px;
        gap: 30px;
        background-color: rgb(83, 83, 151);
        transition: right 0.3s ease;
        padding: 20px;
        border-radius: 20px;
    }
    .navbar-link-list.visible {
        right: 0;
    }
    .navbar-link-list.visible li{
        box-shadow: rgb(0, 0, 0) 0px 14px 28px, rgb(0, 0, 0) 0px 10px 10px;
        padding: 15px;
        border-radius: 10px;
        text-align: center;
    }
}

@media screen and (max-width:600px) {
    .navbar-logo .link{
        display: none;
    }
    .navbar-logo .link-abbr{
        display: block;
    }
    .navbar-search-icon{
        margin-right: 0px;
    }
    .search-bar.open {
        width: 150px;
     }
     .navbar-link-list {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 60px;
        right: -100%;
        width: 200px;
        gap: 30px;
        background-color: rgb(83, 83, 151);
        transition: right 0.3s ease;
        padding: 20px;
        border-radius: 20px;
      }
      .navbar-link-list.visible {
        right: 0;
      }
      .navbar-link-list.visible li{
        box-shadow: rgb(0, 0, 0) 0px 14px 28px, rgb(0, 0, 0) 0px 10px 10px;
        padding: 5px;
        border-radius: 10px;
        text-align: center;
      }
}
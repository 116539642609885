.aimcontainer {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aimcontainer h2 {
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
}
.aim-container {
  background: linear-gradient(
    to top,
    rgb(83, 83, 151),
    rgb(162, 162, 247),
    rgb(83, 83, 151)
  );
  position: relative;
  overflow: hidden;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}
.aim-container .all {
  display: flex;
}
.cleft,
.ccenter,
.cright {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  border: 4px solid white;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cleft p,
.ccenter p,
.cright p {
  opacity: 0;
  animation: fadeInText 1s forwards;
  animation-delay: 0.5s;
}
.all div p {
  font-size: 20px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.chains {
  width: 120px;
  height: 120px;
  margin-top: 300px;
}
.three {
  width: 260px;
  height: 260px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 0px;
}

.cleft {
  margin-top: 150px;
  background: rgb(46, 189, 255);
}
.ccenter {
  background: rgb(84, 255, 84);
}
.cright {
  margin-top: 150px;
  background: rgb(255, 190, 69);
}
@media screen and (max-width: 1050px) {
  .aim-container {
    width: 100%;
  }
  .cleft,
  .ccenter,
  .cright {
    width: 200px;
    height: 200px;
  }
  .cleft,
  .cright {
    margin-top: 100px;
  }
  .chains {
    width: 100px;
    height: 100px;
    margin-top: 250px;
  }
  .three {
    width: 200px;
    height: 200px;
  }

  .all div p {
    font-size: 16px;
  }
}
@media screen and (max-width: 700px) {
  .cleft,
  .ccenter,
  .cright {
    width: 150px;
    height: 150px;
  }
  .cleft,
  .cright {
    margin-top: 80px;
  }
  .three {
    width: 150px;
    height: 150px;
  }
  .chains {
    width: 70px;
    height: 70px;
    margin-top: 180px;
  }

  .all div p {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .aim-container {
    width: 100%;
    background: white;
    box-shadow: none;
  }
  .all {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .three {
    display: none;
  }
  .cleft,
  .ccenter,
  .cright {
    width: 300px;
    height: 100px;
    border-radius: 20px;
  }
  .all div p {
    font-size: 18px;
  }
  .chains {
    width: 50px;
    height: 50px;
    margin-top: 0px;
  }
  .cleft,
  .cright {
    margin-top: 0px;
  }
  .cright {
    margin-bottom: 30px;
  }
  .center {
    margin-bottom: 0px;
  }
}

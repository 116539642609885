.iso-container{
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    box-shadow: var(--box-shadow);
    padding: 30px;
    border-radius: 20px;
    color: rgb(83, 83, 151);
}
.iso-container span{
    font-weight: bold;
}
.iso-container h1{
    margin-bottom: 10px;
    font-size: 30px;
}

.iso-container h2{
    font-size: 18px;
    border: none;
    padding: 0px;
    box-shadow: none;
    margin: 0px;
    margin-left: 20px;
}
.iso-container p{
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
    margin-left: 50px;
}
.iso-container .fp{
    margin-left: 0px;
}

@media screen and (max-width:600px) {
    .iso-container{
        width: 100%;
        gap: 10px;
        padding: 15px;
    }
    .iso-container h1{
        margin-bottom: 10px;
        font-size: 24px;
    }
    
    .iso-container h2{
        font-size: 18px;
        border: none;
        padding: 0px;
        box-shadow: none;
        margin: 0px;
        margin-left: 5px;
    }
    .iso-container p{
        color: black;
        margin-bottom: 15px;
        margin-left: 20px;
    }
    .iso-container span{
        font-weight: bold;
        font-size: 16px;
    }
}
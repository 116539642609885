.mnf-container{
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    box-shadow: var(--box-shadow);
    padding: 30px;
    border-radius: 20px;
    color: rgb(83, 83, 151);
}
.mnf-container span{
    font-weight: bold;
    font-size: 18px;
}
.mnf-container h1{
    margin-bottom: 10px;
    font-size: 30px;
}

.whycard-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 30px;
}
.why-cards{
    box-shadow: var(--box-shadow);
    /* border-radius: 30px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 15px;
}
.why-cards span{
    font-size: 16px;
}
.why-cards h2 i {
    margin: 0px 10px;
    font-size: 1.5em;
}
.whycard-container .why-cards h2{
    margin-top: 20px;
}

.mnf-container h2{
    font-size: 18px;
    border: none;
    padding: 0px;
    box-shadow: none;
    margin: 0px;
    margin-left: 20px;
}
.mnf-container p{
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
    margin-left: 20px;
}
.mnf-container .fp{
    margin-left: 0px;
}
.mnf-container .pmt-link{
    font-weight: bold;
    background-color: rgb(83, 83, 151);
    color: white;
    cursor: pointer;
    padding: 10px;
    display: inline-block;
    border-radius: 10px;
}

@media screen and (max-width:600px) {
    .mnf-container{
        width: 100%;
        gap: 10px;
        padding: 15px;
    }
    .mnf-container h1{
        margin-bottom: 10px;
        font-size: 24px;
    }
    
    .mnf-container h2{
        font-size: 18px;
        border: none;
        padding: 0px;
        box-shadow: none;
        margin: 0px;
        margin-left: 5px;
    }
    .mnf-container p{
        color: black;
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 20px;
    }
    .mnf-container span{
        font-weight: bold;
        font-size: 16px;
    }
    .why-cards{
        gap: 20px;
        border-radius: 20px;
    }
}
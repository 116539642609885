.productpage-container {
  width: 100%;
  margin-top: 100px;
  display: flex;
  /* justify-content: center; */
}
.products-name-container {
  max-width: 25%;
  border-right: 1px solid rgb(83, 83, 151);
  padding: 20px;
}

h2 {
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
  box-shadow: var(--box-shadow1);
  border-radius: 10px;
  margin-bottom: 20px;
}
.products-namelist {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.products-name-container .products-namelist h2 {
  font-size: 16px;
  margin-bottom: 0px;
}
.products-namelist .names {
  background-color: rgb(83, 83, 151);
  padding: 10px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
    box-shadow: var(--box-shadow1);
  }
}

.product-right {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.product-clicked {
  display: flex;
  flex-direction: column;
}
.product-clicked h1 {
  margin-bottom: 20px;
  text-align: center;
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
  box-shadow: var(--box-shadow1);
  border-radius: 10px;
  font-size: 24px;
}
.product-clicked ul {
  padding: 25px 50px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 10px;
  gap: 10px;
  box-shadow: var(--box-shadow1);
}
.product-right .product-clicked ul li {
  color: rgb(83, 83, 151);
  font-size: 18px;
}
.img-cat{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pdf {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: space-evenly;
}
.pdf a {
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: var(--box-shadow1);
  font-weight: bold;
  &:hover {
    color: green;
  }
}
.catalog {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(83, 83, 151);
}
.property{
  font-weight: bold;
}
table{
  border: 2px solid rgb(83, 83, 151);
}
tr,td{
  padding: 2px 5px;
}
.specification{
  margin-top: 50px;
}
.specification h2{
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}
.product-clicked img {
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 350px;
  border-radius: 10px;
  margin-top: 15px;
  border: 1px solid rgb(83, 83, 151);
  cursor: pointer;
  &:hover {
    box-shadow: var(--box-shadow1);
  }
}
.product-right {
  width: 100%;
}
.similar-products {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.similar-products h2 {
  text-align: center;
  width: 100%;
}

h2 {
  border: 1px solid blue;
}

@media screen and (max-width: 600px) {
  .productpage-container {
    flex-direction: column;
  }
  .products-name-container {
    max-width: 100%;
  }
  .products-name-container h2 {
    font-size: 16px;
  }
  .products-namelist .names {
    font-size: 14px;
  }
  .product-right .product-clicked ul {
    display: none;
  }
  .product-clicked h1 {
    font-size: 16px;
  }

  .pdf {
    flex-direction: column;
    gap: 10px;
  }
  .pdf a {
    margin: 10px 0px;
  }
  .img-cat img{
    width: 320px;
    height: 300px;
  }
  .specification{
    margin-top: 0px;
  }
  tr,td{
    font-size: 12px;
  }
}

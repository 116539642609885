.eqp-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.eqp-container .eqp-h2 {
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
}
.equipments-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  width: 80%;
  gap: 30px;
  row-gap: 30px;
}

.product-container {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.product-container h2 {
  margin-top: 20px;
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid  rgb(83, 83, 151) ;
}
.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  width: 80%;
  margin-top: 30px;
  gap: 30px;
  row-gap: 30px;
}
.pagination {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination-btn {
  background-color: rgb(83, 83, 151);
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 600px) {
  .product-container h1 {
    padding: 10px;
    font-size: 18px;
  }
  .products {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

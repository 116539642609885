.card-container{
  padding: 20px;
  background-color: rgb(83, 83, 151);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
}
.card-image{
    margin-top: 15px;
    width: 250px;
    height: 210px;
    border-radius: 15px;
    transition: all ease 0.5s;
    &:hover{
      transform: scale(1.5);
      box-shadow: var(--box-shadow1);
    }
}
.card-container h2{
    margin-top: 10px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    border: none;
    margin-bottom: 0px;
}

@media screen and (max-width:600px) {
  .card-container{
    box-shadow: var(--box-shadow1);
    background: none;
  }
  .card-container h2{
    box-shadow: var(--box-shadow1);
    border-radius: 10px;
    padding: 0px 10px;
    color: rgb(83, 83, 151);
}
.card-image{
  width: 280px;
  height: 210px;
  &:hover{
    transform: scale(1.05);
  }
}
}
.qlt-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}
.qlt-container h2 {
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
}
.quality-container {
  background: linear-gradient(
    to right,
    rgb(83, 83, 151),
    rgb(162, 162, 247)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 80%;
  padding: 40px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}
.isoicon,.misoicon {
  width:300px;
  height: 300px;
  border-radius: 10%;
  box-shadow: var(--box-shadow);
  background-color: white;
  &:hover {
    transform: scale(1.01);
  }
  cursor: pointer;
}
.quality-details{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.quality-details p {
  color: white;
  font-size: 20px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 15px;
}
.misoicon{
  display: none;
}

@media screen and (max-width: 1000px) {
  .isoicon {
    width: 150px;
    height: 150px;
  }
  .quality-details p {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .quality-container {
    width: 100%;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
  }
  .misoicon{
    display: block;
    width: 150px;
    height: 150px;
  }
  .qdp1{
    display: none;
  }
  .isoicon {
   display: none;
  }
  .quality-details {
    gap: 10px;
  }
  .quality-details p {
    font-size: 16px;
  }
}

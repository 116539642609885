.inf-container {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.inf-container h2 {
  padding: 10px 20px;
  color: rgb(83, 83, 151);
  border: 1px solid rgb(83, 83, 151);
}
.infra-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  gap: 10px;
  box-shadow: var(--box-shadow);
}
.m12,
.m34 {
  display: flex;
  gap: 20px;
  background-color: rgb(83, 83, 151);
  padding: 10px;
}
.m1,
.m4 {
  width: 800px;
}
.m2,
.m3 {
  width: 400px;
}
.infra-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background-color: rgb(83, 83, 151);
}
.infra-details p {
  font-size: 18px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.infra-details h2 {
  color: white;
  border: none;
  margin-bottom: 0px;
  padding: 0px;
}
.infra-smcontainer {
  display: none;
}
@media screen and (max-width: 1280px) {
  .m1,
  .m4 {
    width: 650px;
  }
  .m2,
  .m3 {
    width: 300px;
  }
}
@media screen and (max-width: 1180px) {
  .m1,
  .m4 {
    width: 550px;
  }
  .m2,
  .m3 {
    width: 250px;
  }
}
@media screen and (max-width: 1050px) {
  .m1,
  .m4 {
    width: 500px;
  }
  .m2,
  .m3 {
    width: 200px;
  }
}
@media screen and (max-width: 900px) {
  .m1,
  .m4 {
    width: 400px;
  }
  .infra-details p {
    font-size: 16px;
  }
  .infra-details h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 750px) {
  .m1,
  .m4 {
    width: 350px;
  }
}
@media screen and (max-width: 700px) {
  .m1,
  .m4 {
    width: 300px;
  }
  .m2,
  .m3 {
    width: 150px;
  }
}

@media screen and (max-width: 600px) {
  .infra-container {
    display: none;
  }
  .infra-smcontainer {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
  }
  .m1,
  .m4 {
    width: 350px;
    height: 160px;
    background-color: rgb(83, 83, 151);
    padding: 15px;
  }
  .m2,
  .m3 {
    width: 170px;
    height: 130px;
    background-color: rgb(83, 83, 151);
    padding: 10px;
  }
  .m23 {
    display: flex;
    gap: 5px;
  }
}
